import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from 'redux/reducers/store';
import { ValueChainNode, ValueChainStack, ValueChainTableRow } from 'types/organization/types';
import { Page } from 'types/page';

export interface ValueChainState {
  stack: ValueChainStack;
  table: Page<ValueChainTableRow>;
  root: ValueChainNode | null;
  error: string | null;
  loading: boolean;
}

const initialState: ValueChainState = {
  stack: [],
  table: Page.empty(),
  root: null,
  error: null,
  loading: false,
};

const slice = createSlice({
  name: 'valueChains',
  initialState,
  reducers: {
    resetValueChainError: (state: ValueChainState): void => {
      state.error = null;
    },
    fetchValueChainCompleted: (state: ValueChainState): void => {
      state.loading = false;
    },
    fetchValueChainStarted: (state: ValueChainState): void => {
      state.loading = true;
    },
    fetchValueChainSucceeded: (state: ValueChainState): void => {
      state.loading = false;
    },
    fetchValueChainFailed: (state: ValueChainState, { payload }: PayloadAction<string>): void => {
      state.loading = false;
      state.error = payload;
    },

    resetValueChain: (state: ValueChainState): void => {
      const { stack } = state;
      if (stack.length === 0) {
        return;
      }

      state.stack = [stack[0]];
    },
    destroyValueChain: (state: ValueChainState): void => {
      state.stack = [];
      state.table = Page.empty();
    },
    addValueChainNodeWithCategory: (
      state: ValueChainState,
      { payload }: PayloadAction<[ValueChainNode, ValueChainNode]>,
    ): void => {
      state.stack = [...state.stack, ...payload];
    },
    addValueChainNode: (state, { payload }: PayloadAction<ValueChainNode>) => {
      state.stack = [...state.stack, payload];
    },
    goToValueChainNodeAt: (state, { payload }: PayloadAction<number>) => {
      const { stack } = state;
      state.stack = stack.slice(0, payload);
    },
    setCurrentValueChainNodeChildren: (
      state,
      { payload }: PayloadAction<ValueChainNode[]>,
    ): void => {
      const { stack } = state;
      // Just update the 1 element
      state.stack[stack.length - 1].children = payload;
    },
    updateCurrentValueChainNodeChildren: (
      state,
      { payload }: PayloadAction<ValueChainNode[]>,
    ): void => {
      const { stack } = state;
      // Just update the 1 element
      state.stack[stack.length - 1].children =
        state.stack[stack.length - 1].children?.concat(payload);
    },
    setValueChainTableData: (state, { payload }: PayloadAction<Page<ValueChainTableRow>>): void => {
      state.table = payload;
    },
    updateValueChainTableData: (
      state,
      { payload }: PayloadAction<Page<ValueChainTableRow>>,
    ): void => {
      const { data: oldData } = state.table;
      const { data: newData } = payload;

      state.table = { ...payload, data: [...oldData, ...newData] };
    },
  },
});

export default slice.reducer;

export const {
  fetchValueChainStarted,
  fetchValueChainCompleted,
  fetchValueChainFailed,
  fetchValueChainSucceeded,

  resetValueChain,
  destroyValueChain,
  addValueChainNode,
  addValueChainNodeWithCategory,
  goToValueChainNodeAt,
  setCurrentValueChainNodeChildren,
  updateCurrentValueChainNodeChildren,
  setValueChainTableData,
  updateValueChainTableData,
  resetValueChainError,
} = slice.actions;

export const valueChainStateSelector = (state: ApplicationState): ValueChainState =>
  state.valueChain;
