import { AlertsMenuLabel } from 'components/sidebar/alertsMenuLabel';
import { QuickStartButton } from 'components/sidebar/quickStartButton';
import { MenuGroupItem, MenuItem } from 'components/sidebar/types';
import React from 'react';
import { isMobile } from 'react-device-detect';

const optionalQuickStartOptions: readonly MenuItem[] = process.env.REACT_APP_DATA_PORTAL
  ? []
  : [
      {
        id: 'my-watchlist',
        label: 'WATCHLIST',
        kind: 'nav',
        path: '/watchlist',
        tooltipText:
          'Add to your smart watchlist to discover related sensitivities and personalize your experience.',
      },
      {
        id: 'portfolios',
        label: 'PORTFOLIOS',
        kind: 'nav',
        path: '/portfolios',
        comingSoon: isMobile,
        tooltipText:
          'Build smart portfolios. Define relationships to them. Track more data. Receive personalized intelligence.',
      },
    ];

const quickStartOptions: MenuItem[] = [
  {
    id: 'feed',
    label: 'FEED',
    kind: 'nav',
    path: '',
    tooltipText: 'Quickly read news and intelligence tailored to your interests',
  },
  ...optionalQuickStartOptions,
];

const peerComps: readonly MenuItem[] = process.env.REACT_APP_DATA_PORTAL
  ? []
  : [
      {
        id: 'peer-groups',
        label: 'COMPARE',
        kind: 'nav',
        path: '/peer-groups',
        comingSoon: isMobile,
        tooltipText:
          'Build and save comparison or peer groups. Receive automated insights about the group.',
      },
    ];

const smartToolsOptions: MenuItem[] = [
  {
    id: 'notifications',
    label: <AlertsMenuLabel />,
    kind: 'nav',
    path: '/notifications',
    tooltipText: 'Track millions of data points and receive personalized alerts for key movements.',
    comingSoon: isMobile,
  },
  {
    id: 'algo-notes',
    label: 'ALGO NOTES',
    kind: 'nav',
    path: '/algo-notes',
    tooltipText: 'Automated processing of financial statements, peer groups and industry data.',
  },
  ...peerComps,
  {
    id: 'geographies-and-maps',
    label: 'MAPS',
    kind: 'nav',
    path: '/geographies-and-maps',
    tooltipText: 'View the geographic presence and reported locations of sales of companies.',
  },
  {
    id: 'value-chains',
    label: 'VALUE CHAINS',
    kind: 'nav',
    path: '/value-chains',
    tooltipText:
      'Interactive visualizations of items required for value creation including key stakeholders, suppliers, customers & industries.',
  },
  {
    id: 'timeline',
    label: 'TIMELINE & EVENTS',
    kind: 'nav',
    path: '/timeline',
    comingSoon: isMobile,
    tooltipText:
      'Keep track of key events in updated and categorized streams with notifications and automated insights.',
  },
];

const dataAnalysisOptions: MenuItem[] = [
  {
    id: 'company',
    label: 'COMPANY',
    kind: 'nav',
    path: '/data-analysis/company',
    tooltipText:
      'Detailed company insights with automated one-click memos, risk factors, supply chains and more.',
  },
  {
    id: 'country',
    label: 'COUNTRY',
    kind: 'nav',
    path: '/data-analysis/country',
    comingSoon: isMobile,
    tooltipText: 'A summary available with more coming soon.',
  },
  {
    id: 'industry',
    label: 'INDUSTRY',
    kind: 'nav',
    path: '/data-analysis/industry',
    comingSoon: isMobile,
    tooltipText:
      'View dynamic insights about industries such as average margins, valuation metrics and annual growth.',
  },
  {
    id: 'indicators',
    label: 'COMMODS & INDS',
    kind: 'nav',
    path: '/data-analysis/indicators',
    comingSoon: isMobile,
    tooltipText: 'View and track data related to commodities, currencies and rates ',
  },
];

export const groups: readonly MenuGroupItem[] = [
  {
    label: <QuickStartButton />,
    id: 'quickStart',
    openByDefault: true,
    children: quickStartOptions,
    selectable: true,
    path: '/',
    frozen: true,
  },
  ...(process.env.REACT_APP_DATA_PORTAL
    ? []
    : [
        {
          label: 'MARKETPLACE',
          id: 'earn-n-buy',
          openByDefault: true,
          children: [],
          path: '/earn-n-buy',
          selectable: true,
          tooltipText:
            'Earn income from your expertise or content. Purchase beneficial items. View special offers.',
        },
      ]),
  {
    label: 'SMART TOOLS',
    id: 'smart-tools',
    openByDefault: true,
    children: smartToolsOptions,
    path: '/notifications',
    selectable: false,
  },
  {
    label: 'DATA SCREENERS',
    id: 'data-analysis',
    openByDefault: true,
    children: dataAnalysisOptions,
    path: '/data-analysis/company/popular',
    selectable: false,
  },
  {
    label: 'FAQs & HELP',
    id: 'support',
    openByDefault: isMobile,
    children: [],
    path: '/help',
    selectable: false,
  },
];
