import React from 'react';
import { Link } from 'react-router-dom';

export const DataDisclaimer: React.FC = (): React.ReactElement => {
  return (
    <p className="mt-4 text-xs text-gray">
      Sources: Company Reports, Generative AI, the Capnote Community and Independent Researchers.{' '}
      <Link to="/terms-and-conditions">Terms & Conditions</Link> apply
    </p>
  );
};
